import { Button } from 'app/components/button'
import { Icon } from 'app/components/icon/icon'
import { Image } from 'app/components/image'
import { Space } from 'app/components/space'
import { useFriendContext } from 'app/provider/friend-context'
import { config } from 'app/redux/config'
import { strToBase64 } from 'app/util/helper'
import { Text, View, useSx } from 'dripsy'
import React from 'react'
import { TouchableOpacity, Image as ImageNative, Platform } from 'react-native'
import { useRouter } from 'solito/router'

export interface NavBarProps {
  user?: any
  logout?: (navigate: any) => void
  clientConfig: any
  isProfileIcon?: boolean
  isBackIcon?: boolean
  onBackClick?: () => void
  isShowFriendIcon?: boolean
  loginSignUpBtn?: boolean,
  logoPositionCenter?:boolean
}
export function NavBar({
  clientConfig,
  user,
  isProfileIcon = true,
  isBackIcon = false,
  onBackClick,
  isShowFriendIcon = false,
  loginSignUpBtn = false,
  logoPositionCenter = true
}: NavBarProps) {
  const sx = useSx()
  const { push, back } = useRouter()
  const { friendRequestCount } = useFriendContext()

  return (
    <View
      style={[
        sx({
          backgroundColor: '$white',
          height: 60,
          borderBottomColor: '$gray',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          paddingRight: [0, 3],
          position: 'absolute',
          zIndex: 9999,
          paddingX: [20],
          justifyContent: 'space-between',
        }),
      ]}
    >
      {/* Left view */}
      {user && isProfileIcon ? (
        <View>
          <TouchableOpacity
            onPress={() => {
              push('/profile/[userId]', `/profile/${user?._id}`)
            }}
            style={{
              width: 60,
              height: 60,
              // backgroundColor:'red',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
          >
            <Image
              source={user?.avatar ? { uri: `${config.baseUrl}/api/v1/images/${strToBase64(user?.avatar)}` } : require('../../../assets/avatar.png')}
              defaultSource={require('../../../assets/avatar.png')}
              style={{
                width: 33,
                height: 33,
                borderRadius: 50,
              }}
              alt="user avatar"
            />
          </TouchableOpacity>
        </View>
      ) : (
        <></>
      )}

      {isBackIcon ? (
        <View>
          <Button
            onPress={() => {
              if (onBackClick) {
                onBackClick()
              } else back()
            }}
            variantType="text"
            style={{
              height: 60,
              width: 60,
              marginLeft: -20,
              justifyContent: 'center',
            }}
          >
            <Icon name="backArrow" />
          </Button>
        </View>
      ) : (
        <></>
      )}
      {/* Center view */}
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginLeft: isProfileIcon || isBackIcon ? -30 : 0,
          flex: 1,
          ...logoPositionCenter ? { justifyContent:  'center' }:{ }
        }}
      >
        <ImageNative
          source={clientConfig.logoIcon}
          style={{
            width: 150,
            height: 33,
            borderRadius: 11,
            marginRight: 5,
          }}
          alt="logo"
          resizeMode="contain"
        />
      </View>
      {/* Right view */}
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {Platform.OS === 'web' && user && user?.customerIdentifier && user?.productCode ? <Button
          variantType="contained"
          style={sx({
            width: 150,
            height: 25,
            borderWidth: 0,
            display: ['none', 'none', 'flex']
          })}

          onPress={() => {
            push('/api-playground')
          }}

        >
          Api Playground
        </Button> : <></>

        }

        {isShowFriendIcon ? (
          <Button
            variantType="text"
            style={{
              height: 60,
              width: 60,
              position: 'relative',
              alignItems: 'center', justifyContent: 'center'
            }}
            onPress={() => {
              push('/friends-request')
            }}
          >
            <>
              {!!friendRequestCount && <View
                style={{
                  minWidth: 18,
                  height: 18,
                  backgroundColor: 'red',
                  borderRadius: 50,
                  position: 'relative',
                  top: -11,
                  left: -10,
                  zIndex: 999,
                  paddingLeft: 2,
                  paddingRight: 2,
                }}
              >
                <Text
                  ellipsizeMode='tail'
                  numberOfLines={1}
                  style={{ textAlign: 'center', color: '#fff', fontSize: 12, maxWidth: 26, marginTop: 1 }}
                >
                  {friendRequestCount}
                </Text>
              </View>}
              <Icon
                name="friends"
                style={{ width: 25, height: 25, position: 'absolute' }}
              />
            </>
          </Button>
        ) : (
          <></>
        )}

        {!user && loginSignUpBtn && <Space>
          <Button
            variantType="outlined"
            style={sx({
              width: 100,
              height: 35,
              marginRight: 10
            })}

            onPress={() => {
              push('/account/signin')
            }}

          >
            Sign In
          </Button><Button
            variantType="contained"
            style={sx({
              width: 100,
              height: 35,
            })}

            onPress={() => {
              push('/account/signup')
            }}

          >
            Sign Up
          </Button>

        </Space>}
      </View>
    </View>
  )
}
