import React, { useEffect, useState } from 'react';
import { View, ActivityIndicator, ImageProps, StyleSheet, ViewStyle } from 'react-native'; 
import {Image as ImageReactNative,} from 'dripsy'
 interface ImageWithLoaderProps extends ImageProps {
  loaderSize?: 'small' | 'large';
  loaderColor?: string;
  containerStyle?:ViewStyle
}

export const Image: React.FC<ImageWithLoaderProps> = ({
  loaderSize = 'small',
  loaderColor = '#0000ff',
  containerStyle,
  defaultSource,
  source,
  ...props
}:any) => {
  const [imageSource, setImageSource] = useState(source);
  const [loading, setLoading] = useState(true);

  const handleImageLoad:any = (error) => {
    setLoading(false);
  };

  useEffect(()=>{
    setImageSource(source)
  },[source])

  return (
    <>
      <ImageReactNative
        source={imageSource}
        onLoadEnd={handleImageLoad}
        onError={()=>{
          setLoading(false);
          setImageSource(defaultSource)
        }}
        {...props}
      />
      {loading && (
        <ActivityIndicator
          size={loaderSize}
          style={styles.loader}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  loader: {
    alignSelf:'center',
    position:'absolute'
  }
});

